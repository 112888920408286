<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        For each system shown below, determine the
        <b>dominant</b> intermolecular force for the species.
      </p>

      <template v-for="(molecule, i) in molecules">
        <p :key="`p-${molecule}`" class="mb-0">
          {{ abcd[i] }}) <chemical-latex :content="molecule" />
        </p>

        <v-radio-group
          :key="`radios-${molecule}`"
          v-model="inputs[`imf${i + 1}`]"
          class="pl-8"
          :disabled="!allowEditing"
        >
          <v-radio
            v-for="option in options1"
            :key="option.value"
            class="d-inline-block"
            :value="option.value"
          >
            <template #label>
              <stemble-latex :content="option.expression" />
            </template>
          </v-radio>
        </v-radio-group>
      </template>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question254',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        imf1: null,
        imf2: null,
        imf3: null,
        imf4: null,
      },
      abcd: ['a', 'b', 'c', 'd'],
      options1: [
        {expression: '$\\text{Dispersion Forces}$', value: 'dispersion'},
        {expression: '$\\text{Dipole-Dipole}$', value: 'dipoleDipole'},
        {expression: '$\\text{Hydrogen Bonding}$', value: 'hBonding'},
        {expression: '$\\text{Ion Dipole}$', value: 'ionDipole'},
        {
          expression: '$\\text{Ion-Induced Dipole}$',
          value: 'ionInducedDipole',
        },
        {
          expression: '$\\text{Dipole-Induced Dipole}$',
          value: 'dipoleInducedDipole',
        },
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          return {
            molecule3: 'H3C\\text{-}CH3', // dispersion
            molecule1: 'HCl', // dipole-dipole
            molecule2: 'CH3OH', // h-bonding
            molecule4: 'H3C\\text{-}C(\\text{=}O)\\text{-}H', // dipole-dipole
          };
        case 2:
          return {
            molecule3: 'H2', // dispersion
            molecule1: 'H2C\\text{=}O', // dipole-dipole
            molecule2: 'HF', // h-bonding
            molecule4: 'Ne', // dispersion
          };
        case 3:
          return {
            molecule3: 'CH4', // dispersion
            molecule1: 'H3C\\text{-}H2C\\text{-}O\\text{-}CH2\\text{-}CH3', // dipole-dipole
            molecule2: 'H3C\\text{-}NH2', // h-bonding
            molecule4: 'CO', // dipole-dipole
          };
        case 4:
          return {
            molecule3: 'Br2', // dispersion
            molecule1: 'H3C\\text{-}O\\text{-}CH3', // dipole-dipole
            molecule2: 'NH3', // h-bonding
            molecule4: 'H2S', // dipole-dipole
          };
        case 5:
          return {
            molecule3: 'CH3CH2CH3', // dispersion
            molecule1: 'CH3Cl', // dipole-dipole
            molecule2: 'H2O', // h-bonding
            molecule4: 'I2', // dispersion
          };
        default:
          return {
            molecule1: '',
            molecule2: '',
            molecule3: '',
            molecule4: '',
          };
      }
    },
    molecules() {
      return [
        this.versionData.molecule1,
        this.versionData.molecule2,
        this.versionData.molecule3,
        this.versionData.molecule4,
      ];
    },
  },
};
</script>
